<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card p-2">
        <!-- <div>
        <h3 class="font-weight-bolder text-center">
          รายการขายล็อตเตอรี่
        </h3>
      </div> -->
        <div class="row">
          <div class="col-2" />
          <div class="col-8">
          <!-- <div>
            <label for="example-datepicker">เลือกรอบวันที่</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="round"
              class="mb-1"
            />
          </div> -->
          <!-- <div>
            <label for="lotto">กรอกเลขล็อตเตอรี่</label>
            <input
              id="lotto"
              v-model="lotto"
              type="text"
              class="form-control"
              placeholder="กรอกเลขล็อตเตอรี่"
            >
          </div> -->

          <!-- <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="onSubmit()"
            >
              ค้นหาข้อมูล
            </button>
          </div> -->
          <!-- <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="ExportExcel(round)"
            >
              Export
            </button>
          </div> -->
          </div>
          <div class="col-2" />
        </div>
        <!-- -----------------ขข--- -->
        <div class="card-body">
          <div>
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-search" /> ค้นหา
            </h2>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="username">มาจาก</label>
                <b-form-select
                  v-model="buytype"
                  :options="buytype_op"
                  placeholder="มาจาก"
                />
              </div>
            </div>
            <!-- <div class="col-md-2">
            <div class="form-group">
              <label for="username">เลือกธนาคาร</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                placeholder="เลือกธนาคาร"
              />
            </div>
          </div> -->
            <div class="col-md-3">
              <div class="form-group">
                <label for="username">Keyword</label>
                <input
                  id="username"
                  v-model="search_val"
                  type="text"
                  class="form-control"
                  placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                >
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="example-datepicker">เลือกตามงวด</label>
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="round"
                  class="mb-1"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="dateStart">วันที่เริ่มต้น</label>
                <flat-pickr
                  v-model="dateStart"
                  class="form-control"
                  placeholder="เลือกเวลาเริ่มต้น"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="dateEnd">วันที่สิ้นสุด</label>
                <flat-pickr
                  v-model="dateEnd"
                  class="form-control"
                  placeholder="เลือกเวลาสิ้นสุด"
                />
              </div>
            </div>
            <div class="col-md-1 mt-2">
              <button
                class="btn btn-gradient-dark"
                @click="getList()"
              >
                <feather-icon icon="SearchIcon" />
                ค้นหา
              </button>
            </div>
            <div class="col-md-1 mt-2">
              <button
                class="btn btn-gradient-primary btn-block"
                @click="ExportExcel(round)"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <!-- <div class="d-flex mt-2 ml-2">
        <b-button
          variant="gradient-success"
          class="rounded mr-1"
        >
          พร้อมจำหน่าย
        </b-button>

        <b-button
          variant="gradient-warning"
          class="rounded"
        >
          จำหน่ายแล้ว
        </b-button>
      </div> -->
        <b-table
          hover
          show-empty
          responsive
          :items="items"
          :fields="fields"
          class="mt-1"
        >
          <template #cell(id)="data">
            <router-link :to="{ name: '' }">
              {{ data.item.id }}
            </router-link>
          </template>

          <template #cell(depfirst_amount)="data">
            {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
          </template>
          <template #cell(created_at)="data">
            ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
          </template>
          <template #cell(Time)="data">
            {{ Timeformat(data.item.created_at) }}
          </template>
          <template #cell(buytype)="data">
            <p v-if="data.item.from === 'ซื้อสลาก'">
              {{ data.item.buytype === 'credit' ? 'ซื้อด้วยเครดิต' : 'ซื้อด้วยเพชร' }}
            </p>
            <p v-else>
              {{ data.item.from }}
            </p>
          </template>

          <template #empty="scope">
            <div class="p-2 text-center">
              <img
                src="/box.png"
                alt="empty"
                height="40px"
              > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
            </div>
          </template>

          <template #cell(status)="data">
            <span
              v-if="data.item.status === 0"
              class="badge badge-warning badge-circle"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>พร้อมจำหน่าย</span>
            </span>

            <span
              v-else
              class="badge badge-success"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>จำหน่ายแล้ว</span>
            </span>
          </template>

          <template #cell(action)>
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                class="mr-1"
                size="sm"
              >
                <i class="fa fa-edit" />
              </b-button>
            <!-- <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button> -->
            </div>
          </template>
        </b-table>
        <b-card-body
          class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
        >
          <!-- page length -->
          <b-form-group
            label=""
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <p>จำหน่ายแล้ว {{ totalRows.toLocaleString() }} ชุด / {{ totalItems.toLocaleString() }} ใบ</p>
          <!-- {{ totalRows ? totalRows: 0 }} -->
          <!-- <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          /> -->
          </b-form-group>

          <!-- pagination -->
          <div class="pr-1 pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getList()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BFormDatepicker,
  BTable, BCardBody, BPagination, BFormGroup, BButton,
  BOverlay, BIconController,
  // BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Reward',
  components: {
    flatPickr,
    BFormDatepicker,
    BTable,
    BCardBody,
    BPagination,
    BFormGroup,
    BFormSelect,
    // BFormInput,
    BButton,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      buytype: 'ทั้งหมด',
      buytype_op: ['ทั้งหมด', 'ซื้อด้วยเครดิต', 'ซื้อด้วยเพชร', 'หมุนกงล้อ', 'กล่องสุ่ม'],
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      search_val: null,
      lotto: '',
      list: [],
      round: null,
      fields: [
        { key: 'buytype', label: 'ซื้อด้วย' },
        { key: 'lottonumber', label: 'เลขลสาก' },
        { key: 'count', label: 'จำนวน(ใบ)' },
        { key: 'status', label: 'สถานะ' },
        { key: 'roundth', label: 'งวดประจำวันที่' },
        // { key: 'set_no', label: 'เล่มที่' },
        // { key: 'agent_buy', label: 'ตัวเทน' },
        { key: 'member_buy', label: 'ยูสเซอร์เนม' },
        { key: 'member_tel', label: 'เบอร์โทร' },
        { key: 'Time', label: 'เวลาที่ซื้อสลาก' },
        // { key: 'action', label: 'Action' },
      ],
      items: [],
      totalRows: 1,
      totalItems: 0,
      total_lotto: 0,
      currentPage: 1,
      perPage: 15,
      show: false,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    ExportExcel() {
      this.show = true
      this.$http
        .get('lottery/export', { responseType: 'blob' })
        .then(response => {
          // console.log(response.data)
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([response.data]),
          )
          link.setAttribute('download', `รายการสลากงวดวันที่ ${this.items[0].round}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.show = false
        })
        .catch(error => console.log(error))
    },
    Timeformat(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    getList() {
      this.show = true
      const params = {
        page: this.currentPage,
        round: this.round,
        perPage: this.perPage,
        buytype: this.buytype,
        search_val: this.search_val,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/lottery/Getlotto-purchased', params)
        .then(response => {
          this.show = false
          this.items = response.data.lottery
          this.totalRows = response.data.total
          this.totalItems = response.data.sum
          this.total_lotto = response.data.lottery.reduce(
            (prev, cur) => prev + Number(cur.count),
            0,
          )
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.table {
    border-spacing: 0 15px;
    border-collapse: separate;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    scrollbar-width: none;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table tbody tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
    border-radius: 5px 0 0 5px;
}
.table tbody tr td {
    background: #fff;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
